<template>
  <aside class="notification">
    <CircleButton class="notification__toggle" @click="(open = true), (currentTab = 0)">
      <i class="el-icon-bell"></i>
      <img
        v-if="checkBadge"
        src="@/assets/images/02_icons/icon_notice_badge.svg"
        class="notification__badge notification__toggle__badge"
        alt="badge"
      />
    </CircleButton>

    <div class="notification__overlay" :class="{ open }" @click="open = false"></div>

    <div class="notification__content" :class="{ open }">
      <div class="notification__content__header">
        <div class="notification__content__header__title">
          <div
            v-for="(tab, index) in ['알림', '체크인']"
            v-bind:key="index"
            class="notification__title"
            :style="isEnter === 0 && index === 1 && 'display: none;'"
          >
            <h5 v-if="open" @click="handleClickTitle(index)" :class="{ active: currentTab === index }">
              {{ tab }}
            </h5>
            <img
              v-if="(tab === '알림' && unreadCount) || (tab === '체크인' && unreadEnterCount)"
              src="@/assets/images/02_icons/icon_notice_badge.svg"
              class="notification__title__badge"
              alt="badge"
            />
          </div>
        </div>
        <div class="notification__content__header__buttons">
          <CircleButton v-if="open" @click="handleClickRefresh(currentTab)"><i class="el-icon-refresh"></i></CircleButton>
          <el-tooltip effect="light" placement="right">
            <span slot="content">모두 읽음</span>
            <CircleButton
              v-if="open"
              :disabled="(currentTab === 0 && !unreadCount) || (currentTab === 1 && !unreadEnterCount)"
              @click="handleClickReadAll(currentTab)"
              ><i class="el-icon-check"></i
            ></CircleButton>
          </el-tooltip>
          <CircleButton v-if="open" :class="{ open }" @click="open = false"><i class="el-icon-arrow-left"></i></CircleButton>
        </div>
      </div>

      <!-- 알림탭 -->
      <div v-show="currentTab === 0">
        <div class="notification__select-box">
          <el-select v-model="filterValue" placeholder="전체 알림" collapse-tags>
            <el-option v-for="item in notiFilterOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </div>

        <ul v-loading="loading" ref="notification-list" class="notification__list" @scroll="handleNotificationListScroll">
          <li
            v-for="(notification, index) in notifications"
            :key="`${notification.id}${index}`"
            class="notification__list__item"
            :class="{ unread: !notification.is_checked }"
          >
            <h6>{{ notification.label }}</h6>
            <div class="icon_time">
              <img src="@assets/images/02_icons/icon_notification_watch.svg" alt="" />
              <span>
                {{ setTime(notification.created_at) }}
              </span>
            </div>
            <p>
              {{ notification.message }}
            </p>
            <div class="notification__list__item__action-buttons">
              <button v-if="getNotificationActionLabel(notification)" @click="handleClickNotificationAction(notification)">
                {{ getNotificationActionLabel(notification) }}
              </button>
              <button v-if="!notification.is_checked" @click="toggleNotificationChecked(notification)">
                읽음
              </button>
            </div>
          </li>
        </ul>
      </div>

      <!-- 체크인탭 -->
      <div v-show="currentTab === 1 && isEnter === 1">
        <div class="notification__today-checkin">
          <p>오늘의 체크인</p>
          <p>{{ enterAttendanceCount }}명</p>
        </div>
        <ul v-loading="loading" ref="notification-list" class="notification__list" @scroll="handleNotificationListScroll">
          <li
            v-for="(noti, index) in checkinNotis"
            :key="index"
            class="notification__list__checkin-item"
            :class="{ unread: !noti.is_checked }"
          >
            <div class="notification__list__checkin-item__title">
              <h6>체크인 완료</h6>
              <div>
                <img src="@assets/images/02_icons/icon_notification_watch.svg" alt="watch-icon" />
                <span>
                  {{ setTime(noti.enter_time) }}
                </span>
              </div>
            </div>
            <div class="notification__list__checkin-item__profile" @click="moveShowMore('users', noti.member_id)">
              <img class="profile-img" :src="getImageUrl(noti.avatar, 0)" alt="profile" />
              <div class="left-wrap">
                <div>
                  <h6>{{ noti.name }}</h6>
                  <h6>회원님</h6>
                </div>
                <img src="@assets/images/02_icons/icon_notification_arrow.svg" alt="" />
              </div>
            </div>
            <div class="notification__list__checkin-item__info-wrap">
              <div>
                <h6>수업일시</h6>
                <h6>{{ noti.lecture_time }}</h6>
              </div>
              <div>
                <h6>수업명</h6>
                <h6>{{ noti.lecture_title }}</h6>
              </div>
            </div>
            <div class="notification__list__item__action-buttons">
              <button @click="moveShowMore('lecture', noti.lecture_id)">
                수업 보기
              </button>
              <button v-if="!noti.is_checked" @click="toggleNotificationChecked(noti, true)">
                읽음
              </button>
            </div>
          </li>
        </ul>
        <div class="notification__content__bottom">
          <button @click="() => moveShowMore('checkin')">체크인 내역 더 보기</button>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
/* eslint-disable */
import * as firebase from 'firebase/app';
import 'firebase/messaging';
import { NOTIFICATION_ACTION_TYPES, NOTIFICATION_ACTION_LABELS, NOTIFICATION_FILTER } from '@constants';

export default {
  props: {
    isEnter: Number,
  },
  data() {
    return {
      open: false,
      filterValue: '',
      fcmNotiData: {},
      currentTab: 0,
      sound: '',
    };
  },
  computed: {
    notiFilterOptions() {
      let filterOptions = [{ value: null, label: '전체 알림' }];
      for (let i = 0; i < Object.keys(NOTIFICATION_FILTER).length; i++) {
        filterOptions.push({
          value: Object.keys(NOTIFICATION_FILTER)[i],
          label: Object.values(NOTIFICATION_FILTER)[i],
        });
      }

      return filterOptions;
    },

    notifications() {
      return this.$store.getters['notification/notifications'];
    },
    checkinNotis() {
      return this.$store.getters['notification/checkinNotis'];
    },
    enterAttendanceCount() {
      return this.$store.getters['notification/enterAttendanceCount'];
    },
    params() {
      return this.$store.getters['notification/params'];
    },
    total() {
      return this.$store.getters['notification/total'];
    },
    checkInTotal() {
      return this.$store.getters['notification/checkInTotal'];
    },
    unreadCount() {
      return this.$store.getters['notification/unreadCount'];
    },
    unreadEnterCount() {
      return this.$store.getters['notification/unreadEnterCount'];
    },
    loading() {
      return this.$store.getters['notification/loading'];
    },
    isOnFCMMessage() {
      return this.$store.getters['notification/isOnFCMMessage'];
    },
    checkBadge() {
      if (this.isEnter === 0) return this.unreadCount !== 0;
      else return this.unreadCount !== 0 || this.unreadEnterCount !== 0;
    },
    hasMore() {
      return this.currentTab === 0 ? this.notifications.length < this.total : this.checkinNotis.length < this.checkInTotal;
    },
  },
  watch: {
    $route: {
      handler() {
        this.dispatchGetNotifications();
      },
      immediate: true,
    },

    filterValue(value) {
      this.handleSelectBox();
      this.$refs['notification-list'].scroll({ top: 0 });
    },
  },
  async created() {
    this.sound = await new Audio(
      'https://check-in-audio.s3.ap-northeast-2.amazonaws.com/household_door_bell_ding_dong_single.mp3',
    );

    this.$store.commit('notification/SET_ON_FCM_MESSAGE', true);

    if (firebase.messaging.isSupported()) {
      firebase.messaging().onMessage(payload => {
        this.handleNewMessage(payload.data);
      });
    }
  },
  methods: {
    dispatchGetNotifications(reset) {
      this.$store.dispatch('notification/getNotifications', reset);
      this.$store.dispatch('notification/getCheckinNoti', reset);
    },

    updateNotifications(currentTab, reset) {
      currentTab === 0
        ? this.$store.dispatch('notification/getNotifications', reset)
        : this.$store.dispatch('notification/getCheckinNoti', reset);
    },

    async handleClickTitle(index) {
      this.currentTab = index;
      this.$refs['notification-list'].scroll({ top: 0, behavior: 'smooth' });
    },

    handleClickRefresh(currentTab) {
      this.$refs['notification-list'].scroll({ top: 0 });
      this.updateNotifications(currentTab, true);
    },

    /** 모두 읽음 처리 */
    async handleClickReadAll(currentTab) {
      if ((currentTab === 0 && !this.unreadCount) || (currentTab === 1 && !this.unreadEnterCount)) return;

      try {
        if (currentTab === 0) await this.$api.notification.readAll();
        else await this.$api.notification.checkinReadAll();

        const currentData = currentTab === 0 ? this.notifications : this.checkinNotis;
        const currentReadCount =
          currentTab === 0
            ? { unchecked_count: 0 }
            : { unchecked_enter_count: 0, enter_attendance_count: this.enterAttendanceCount };

        const data = {
          data: currentData.map(item => ({ ...item, is_checked: true })),
          total: this.total,
          ...currentReadCount,
        };

        currentTab === 1
          ? this.$store.commit('notification/SET_CHECKIN_NOTI', data)
          : this.$store.commit('notification/SET_NOTIFICATIONS', data);
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      }
    },

    async handleNewMessage(data) {
      /**
       * fcm 알람 오면 알람 새로고침, 서버 과부하로 사용중지
       * this.dispatchGetNotifications(true);
       */

      if (!data || data.msg_type !== 'message.enter.staff') return;

      /** 체크인 알림 로직 분리 */
      if (data.enter) return this.handleNewCheckinMsg(data.enter);

      this.sound.play();
      this.$utils.notify.success(this, '출석', data.message);
    },

    async handleNewCheckinMsg(data) {
      const enterData = JSON.parse(await data);
      this.$emit('checkin-success', enterData);
    },

    handleLoadMore() {
      if (this.hasMore) {
        const params = {
          limit: this.params.limit,
          page: this.params.page + 1,
        };
        this.$store.commit('notification/SET_PARAMS', this.filterValue ? { ...params, type: this.filterValue } : params);
        this.updateNotifications(this.currentTab, false);
      }
    },

    async handleSelectBox() {
      const params = {
        limit: this.params.limit,
        page: 1,
      };
      this.$store.commit('notification/SET_PARAMS', this.filterValue ? { ...params, type: this.filterValue } : params);
      this.updateNotifications(this.currentTab, true);
    },

    /** 무한 스크롤 */
    handleNotificationListScroll: _.throttle(function(e) {
      const { scrollHeight, scrollTop, clientHeight } = e.target;
      const isAtTheBottom = scrollTop + clientHeight >= scrollHeight - 1 || scrollTop + clientHeight >= scrollHeight + 1;
      if (isAtTheBottom) this.handleLoadMore();
    }, 250),

    /** 알림 액션 버튼 레이블 반환 */
    getNotificationActionLabel(notification) {
      return NOTIFICATION_ACTION_LABELS[NOTIFICATION_ACTION_TYPES[notification.msg_type]];
    },

    /** 알림 액션 버튼 클릭시 */
    async handleClickNotificationAction(notification) {
      const link = await this.$utils.getNotificationLink(notification);
      if (link) {
        this.$router.push(link).catch(() => {});
        this.open = false;
      }
      if (!notification.is_checked) {
        this.toggleNotificationChecked(notification);
      }
    },

    /** 알림 읽음/읽지않음 토글 */
    async toggleNotificationChecked(notiData, notiType) {
      try {
        const is_checked = !notiData.is_checked ? 1 : 0;

        if (is_checked) await this.$api.notification.read(notiData.id);
        else await this.$api.notification.unread(notiData.id);

        const currentData = notiType ? this.checkinNotis : this.notifications;
        const currentReadCount = notiType
          ? {
              unchecked_enter_count: !is_checked ? this.unreadEnterCount + 1 : this.unreadEnterCount - 1,
            }
          : { unchecked_count: !is_checked ? this.unreadCount + 1 : this.unreadCount - 1 };

        const data = {
          data: currentData.map(item => {
            if (item.id === notiData.id) item.is_checked = is_checked;
            return item;
          }),
          total: this.total,
          ...currentReadCount,
          enter_attendance_count: this.enterAttendanceCount,
        };

        if (notiType) this.$store.commit('notification/SET_CHECKIN_NOTI', data);
        else this.$store.commit('notification/SET_NOTIFICATIONS', data);
      } catch (error) {
        this.$utils.notify.parseError(this, error);
      }
    },

    getImageUrl(path, size) {
      return this.$utils.getImagePath(path, size);
    },

    setTime(time) {
      const today = this.moment().format('YYYY-MM-DD');
      const yesterday = this.moment()
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
      const currentTime = this.moment(time).format('YYYY-MM-DD');

      const checkToday = currentTime === today;
      const checkYesterday = currentTime === yesterday;

      if (checkToday) {
        return this.moment(time).format('HH:mm');
      } else if (checkYesterday) {
        return '1일 전';
      }

      return this.moment(time).fromNow();
    },

    moveShowMore(type, id) {
      if (type === 'checkin') this.$router.push('/lectures/check_in');
      else {
        const currentUrl = `/${type}/detail?id=${id}`;
        this.$router.push(currentUrl);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  @include flex(column, center);
  background: #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  border-right: 1px solid #ebebeb;
  width: 56px;
  z-index: $z-index-notification;

  &__title {
    position: relative;
    margin-right: 20px;
    color: #a3a3a3;

    .active {
      color: #343a40;
    }

    &__badge {
      position: absolute;
      top: 0;
      right: -6px;
    }
  }
  &__toggle {
    margin: 20px 10px;
    position: relative;
    i {
      font-size: 18px;
    }
    &__badge {
      @include flex(row, center, center);
      position: absolute;
      top: 7px;
      right: 5px;
    }
  }
  &__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(#000, 0.2);
    opacity: 0;
    transform: translateX(-100vw);
    transition: opacity 0.2s;
    z-index: $z-index-notification + 1;
    &.open {
      opacity: 1;
      transform: translateX(0);
    }
  }
  &__content {
    position: absolute;
    background: #fff;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    top: 0;
    bottom: 0;
    left: 0;
    width: 90%;
    max-width: 336px;
    transform: translateX(-100%);
    transition: transform 0.2s;
    z-index: $z-index-notification + 2;
    overflow: hidden;
    &.open {
      transform: translateX(0);
    }
    &__header {
      @include flex(row, center, space-between);
      padding: 20px 30px 10px;
      i {
        font-size: 18px;
      }
      &__title {
        @include flex(row, center, center);
        > * {
          margin-right: 15px;
        }
        h5 {
          cursor: pointer;
        }
      }
      &__buttons {
        @include flex(row, center, center);
      }
    }

    &__bottom {
      @include flex();
      position: fixed;
      bottom: 0;
      padding: 15px 32px;
      width: 100%;

      border-top: 1px solid #ebebeb;
      background-color: #fff;

      button {
        width: 100%;
        height: 40px;
        border-radius: 2px;
        background-color: #4799f2;
        color: #fff;
      }
    }
  }

  &__select-box {
    width: 100%;
    text-align: center;
    padding-bottom: 10px;
    /deep/ .el-select {
      width: 80%;
      border: 1px solid #eee;
      border-radius: 4px;
    }
    /deep/ .el-input__inner {
      border-bottom: none;
      padding-left: 10px;
    }
  }

  &__today-checkin {
    @include flex(row, center, space-between);
    margin: 0 auto 10px;
    padding: 8px 12px;
    max-width: 280px;
    height: 36px;
    border-radius: 4px;
    background-color: #fafafa;
    color: #8a8a8a;

    p:last-child {
      color: #1da0ff;
    }
  }

  &__list {
    @include flex(column);
    height: calc(100vh - 70px);
    overflow: auto;
    padding-bottom: 113px;

    &__item {
      border-top: 1px solid #ebebeb;
      border-left: 2px solid transparent;
      font-size: 13px;
      display: grid;
      grid-template-columns: 1fr auto 22px;
      grid-gap: 8px;
      align-items: center;
      padding: 20px 0 0 30px;
      transition: background 0.15s;
      &:first-child {
        border-top: none;
      }
      &.unread {
        border-left-color: $color-primary;
        background: #fafafa;
        span {
          @include flex(row, center, center);
          &::after {
            content: '';
            background: $color-primary;
            width: 8px;
            height: 8px;
            margin-left: 8px;
            border-radius: 50%;
          }
        }
      }
      &:hover {
        background: rgba($color-primary, 0.1);
      }
      h6 {
        font-size: 13px;
      }
      span {
        font-size: 12px;
        color: $color-primary;
      }
      p {
        grid-column: span 2;
      }
      &__action-buttons {
        grid-column: span 3;
        @include flex(row, center, center);
        padding-top: 8px;
        button {
          border-top: 1px solid #ebebeb;
          font-size: 12px;
          padding: 8px;
          transition: background 0.15s;
          width: 100%;
          &:hover {
            background: rgba($color-primary, 0.15);
          }
        }
        button + button {
          border-left: 1px solid #ebebeb;
        }
      }

      .icon_time {
        @include flex();
        gap: 2px;

        img {
          margin-top: 1px;
          width: 16px;
        }
      }
    }

    &__checkin-item {
      padding: 20px 0 0;
      border-top: 1px solid #ebebeb;

      &.unread {
        border-left-color: $color-primary;
        background: #fafafa;
        span {
          @include flex(row, center, center);

          &::after {
            content: '';
            background: $color-primary;
            width: 8px;
            height: 8px;
            margin: 2px 0 0 8px;
            border-radius: 50%;
          }
        }
      }

      &:hover {
        background-color: rgba($color-primary, 0.1);
      }

      &__title {
        @include flex(row, center, space-between);
        margin-bottom: 15px;
        padding: 0 32px;

        h6 {
          font-size: 13px;
        }

        div {
          @include flex();
          gap: 2px;

          img {
            margin-top: 1px;
            width: 16px;
          }

          span {
            font-size: 12px;
            color: $color-primary;
          }
        }
      }

      &__profile {
        @include flex();
        gap: 10px;
        margin-bottom: 10px;
        padding: 0 32px;
        cursor: pointer;

        .profile-img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }

        .left-wrap {
          @include flex(row, center, space-between);
          flex: 1;

          div {
            @include flex(column, flex-start, center);

            h6 {
              display: -webkit-box;
              overflow: hidden;
              max-width: 180px;
              font-weight: 500;
              text-overflow: ellipsis;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              word-wrap: break-word;

              &:last-child {
                color: #a3a3a3;
              }
            }
          }
        }
      }

      &__info-wrap {
        margin-bottom: 8px;
        padding: 0 32px;

        div {
          @include flex(row, center, flex-start);
          gap: 10px;
          margin-bottom: 2px;

          h6 {
            font-weight: 500;
            &:first-child {
              width: 46px;
              color: #a3a3a3;
            }
          }
        }
      }
    }
  }
}
</style>
