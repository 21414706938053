export default async function promiseAllData(func, params) {
  try {
    const resForMeta = await func({ ...params, page: 1, limit: 1 });
    const totalCount = resForMeta.data.meta.total;
    const limit = 40;
    const pageCount = parseInt(totalCount / limit) + (totalCount % limit === 0 ? 0 : 1);
    const promises = Array.from(new Array(pageCount), (_, i) => i + 1).map(page => func({ ...params, page: page, limit: limit }));
    const res = await Promise.all(promises);
    const data = res.map(item => item.data.data).flat();
    return data;
  } catch (e) {
    return [];
  }
}
